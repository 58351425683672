import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  loggedInUser: false,
  redirectAfterLogin: "/sandbox",
  userEmail: null,
  subscription: null,
  monthlyPromptsRemaining: null,
  monthEnd: null,
  disabled: null,
  rejectedScreens: null,
  stripeId: null,
  mode: null,
  cognitoUsername: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.loggedInUser = true;
    },
    logout: (state) => {
      state.loggedInUser = false;
      state.disabled = null;
      state.rejectedScreens = null;
      state.userEmail = null;
      state.subscription = null;
      state.stripeId = null;
    },
    setCognitoUsername: (state, action) => {
      state.cognitoUsername = action.payload;
    },
    setDisabled: (state, action) => {
      state.disabled = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setRedirectAfterLogin: (state, action) => {
      state.redirectAfterLogin = action.payload;
    },
    setRejectedScreens: (state, action) => {
      state.rejectedScreens = action.payload;
    },
    setStripeId: (state, action) => {
      state.stripeId = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setCognitoUsername,
  setDisabled,
  setIsLoading,
  setMode,
  setRedirectAfterLogin,
  setRejectedScreens,
  setSubscription,
  setUserEmail,
  setStripeId,
} = userSlice.actions;

export default userSlice.reducer;
