import { Helmet } from "react-helmet";

import VideoPage from "../../videoPage.component";

function ChromeInstall() {
  return (
    <div>
      <Helmet>
        <title>Genny | Genny for Chrome Installation</title>
      </Helmet>
      <VideoPage
        title="Windows: Chrome Extension Installation"
        description={
          <div>
            In this video, you'll learn how to quickly and easily install Genny
            for Chrome.
          </div>
        }
        embedId="_dW_OeYC-AU"
      />
    </div>
  );
}

export default ChromeInstall;
