import { Auth } from "aws-amplify";
import { sha256 } from "js-sha256";

import handleFlaggedScreening from "../users/handleFlaggedScreening";

const API_URL =
  "https://oy1hu45rzh.execute-api.us-east-1.amazonaws.com/dev/screen-prompt";

async function screenPrompt(prompt, app = "unknown") {
  if (prompt.toLowerCase().includes("sexo ")) return false;
  if (prompt === "test") {
    // USED TO TEST APP WITHOUT WASTING GPT CALLS
    const testData = {
      message: {
        results: [
          {
            flagged: false,
          },
        ],
      },
    };
    console.log(testData);
    return testData;
  }

  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  console.log("Prompt being screened:", prompt);

  const emailAddress = user.attributes.email;
  const hash = sha256.create();
  hash.update(emailAddress);
  const hashedEmailAddress = hash.hex();

  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sourceApp: app,
      user: hashedEmailAddress,
      prompt: prompt,
      log: "false",
    }),
  });
  const data = await response.json();
  console.log(data);
  if (
    !data.message ||
    !data.message.results ||
    data.message.results[0].flagged !== false
  ) {
    if (
      data.message &&
      data.message.results &&
      data.message.results[0].flagged
    ) {
      handleFlaggedScreening(prompt, data);
    }
    return {
      result: data,
      passed: false,
    };
  } else return { result: data, passed: true };
}

export default screenPrompt;
