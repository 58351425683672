/* global chrome */

import { Auth } from "aws-amplify";

const extensionId = "fchkabnafbbcfemenkejfmfogbnmgokj";

async function sendSessionToExtension() {
  if (!chrome) {
    console.log("chrome object not found");
    return;
  }
  try {
    const session = await Auth.currentSession();
    const message = {
      message: "new session",
      session: JSON.stringify(session),
    };
    chrome.runtime.sendMessage(extensionId, message, (response) => {
      if (chrome.runtime.lastError) {
        console.log(chrome.runtime.lastError);
      } else {
        //console.log(response);
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export default sendSessionToExtension;
