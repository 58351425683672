import { Amplify } from "aws-amplify";
import {
  Authenticator,
  ThemeProvider,
  defaultDarkModeOverride,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./login.styles.css";
import awsconfig from "../../../aws-exports";
import components from "./resources/components";
import { login } from "../../../userSlice";

import setUserState from "../../../utilties/users/setUserState";

Amplify.configure(awsconfig);

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectAfterLogin = useSelector(
    (state) => state.user.redirectAfterLogin
  );

  return (
    <div id="login-container">
      <Helmet>
        <title>genny | login</title>
      </Helmet>
      {/* from https://ui.docs.amplify.aws/react/connected-components/authenticator */}
      <ThemeProvider
        theme={{ name: "_", overrides: [defaultDarkModeOverride] }}
        colorMode="dark"
      >
        <Authenticator components={components}>
          {() => {
            //timeout prevents react rendering error
            setTimeout(() => navigate(redirectAfterLogin), 100);
            return <div></div>;
          }}
        </Authenticator>
      </ThemeProvider>
    </div>
  );
}

export default Login;
