import { Helmet } from "react-helmet";

import VideoPage from "../videoPage.component";

function Genny101() {
  return (
    <div>
      <Helmet>
        <title>genny | genny 101</title>
      </Helmet>
      <VideoPage
        title="Genny 101"
        description={
          <div>
            In this video, you'll learn what a prompt is and the central role
            that it plays in how <span className="h3-genny">genny</span> works.
          </div>
        }
        nextTitle="Genny 201: Write Better Prompts"
        nextHref="/howTo/genny201"
        embedId="VGyKZBhb1Uw"
      />
    </div>
  );
}

export default Genny101;
