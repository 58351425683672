import { Link } from "react-router-dom";

import "./home.styles.css";

import gennyConnections from "../../../images/genny-connections.png";
import screenshot1 from "../../../images/genny-for-word-ideas.png";
import screenshot2 from "../../../images/outline_screenshot.png";
import screenshot3 from "../../../images/article_screenshot.png";
import screenshot4 from "../../../images/craigslist_demo.gif";
import screenshot5 from "../../../images/snoop_limerick.png";

function Home() {
  return (
    <div className="App text-light text-center m-1">
      <h1 className="gradient-text headline">unleash your muse</h1>
      <h2 className="mt-2 sub-headline mx-3">
        <span className="brand-color brand-font h2-genny">genny</span> uses the
        latest AI tech to supercharge your favorite creative apps.
      </h2>
      <div id="genny-connections-container" className="container p-4">
        <Link to="/download">
          <img
            id="genny-connections"
            src={gennyConnections}
            alt="Genny connection with many other apps"
          />
        </Link>
      </div>
      {/* <button onClick={testFn}>Test</button> */}
      <div className="container-fluid">
        {/* ------------------------------------ ROW 1 STARTS HERE ------------------------------- */}
        <div className="row mt-4 align-items-center border border-secondary rounded p-1">
          <div className="col-12 col-md-6 p-4">
            <img
              className="img-fluid headline-img"
              src={screenshot1}
              alt="screenshot of genny generating ideas for a blog post"
            />
          </div>
          <div className="col-12 col-md-6 text-start px-5">
            <h2 className="fs-1">
              Use&nbsp;&nbsp;<span className="h2-genny">genny</span>
              &nbsp;&nbsp;to find your spark.
            </h2>
            <p className="fs-3 py-3">
              She's great at coming up with a list of ideas to get you started.
            </p>
          </div>
        </div>
        {/* ------------------------------------ ROW 2 STARTS HERE ------------------------------- */}
        <div className="row mt-4 align-items-center border border-secondary rounded p-1">
          <div className="col-12 col-md-6 text-start px-5">
            <h2 className="fs-1">Go from idea to outline.</h2>
            <p className="fs-3 py-3">
              It only take seconds to turn your idea into a robust outline.
            </p>
          </div>
          <div className="col-12 col-md-6 p-4">
            <img
              className="img-fluid headline-img"
              src={screenshot2}
              alt="screenshot of genny turning an idea into an outline"
            />
          </div>
        </div>
        {/* ------------------------------------ ROW 3 STARTS HERE ------------------------------- */}
        <div className="row mt-4 align-items-center border border-secondary rounded p-1">
          <div className="col-12 col-md-6 p-4">
            <img
              className="img-fluid headline-img"
              src={screenshot3}
              alt="screenshot of genny generating turning an outline into an article"
            />
          </div>
          <div className="col-12 col-md-6 text-start px-5">
            <h2 className="fs-1">Goodbye writer's block.</h2>
            <p className="fs-3 py-3">
              Genny effortlessly transforms your outline into a beautiful draft.
            </p>
          </div>
        </div>
        {/* ------------------------------------ ROW 4 STARTS HERE ------------------------------- */}
        <div className="row mt-4 align-items-center border border-secondary rounded p-1">
          <div className="col-12 col-md-6 text-start px-5">
            <h2 className="fs-1">
              <span className="h2-genny">genny</span>&nbsp;&nbsp;works with the
              tools you already use.
            </h2>
            <p className="fs-3 py-3">
              With apps like Genny for Chrome, your creative tools become
              supercharged.
            </p>
          </div>
          <div className="col-12 col-md-6 p-4">
            <img
              className="img-fluid headline-img"
              src={screenshot4}
              alt="animation of genny creating car ad from bullet points"
            />
          </div>
        </div>
        {/* ------------------------------------ ROW 5 STARTS HERE ------------------------------- */}
        <div className="row mt-4 align-items-center border border-secondary rounded">
          <div className="col-12 col-md-6 p-4">
            <img
              className="img-fluid headline-img"
              src={screenshot5}
              alt="screenshot of genny generating turning an outline into an article"
            />
          </div>
          <div className="col-12 col-md-6 text-start px-5">
            <h2 className="fs-1">The only limit is your creativity.</h2>
            <p className="fs-3 py-3">
              Use Genny to quickly test out any content that you can dream up.
            </p>
          </div>
        </div>
        {/* ------------------------------------------------------------------- */}
        <div className="row text-center px-5 mx-5 mt-3 mb-5">
          <h1 className="">
            Best of all, you can get started right now for free, no credit card
            required.{" "}
            <a href="/login" className="link-info">
              What are you waiting for?
            </a>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Home;
