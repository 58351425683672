import { useState } from "react";
import { Helmet } from "react-helmet";

import "./cars.styles.scss";
import InputForm from "./subcomponents/inputForm.component";
import LoadingBar from "./subcomponents/loadingBar";
import Output from "./subcomponents/output.component";

function Cars() {
  const [output, setOutput] = useState();
  const [isGenerating, setIsGenerating] = useState(false);
  const [modelYear, setModelYear] = useState("");
  const [price, setPrice] = useState("");
  const [VIN, setVIN] = useState("");
  const [mileage, setMileage] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");

  function reset() {
    setOutput("");
    setIsGenerating(false);
    setModelYear("");
    setPrice("");
    setVIN("");
    setMileage("");
    setAdditionalDetails("");
  }

  return (
    <div>
      <div id="cars-container">
        <Helmet>
          <title>genny | cars</title>
        </Helmet>
        <div className="header">
          <div className="cars-header-title">
            <h1>Great Car Ads Fast</h1>
          </div>
          <hr id="header-hr" />
          <div className="header-subtitle">
            <h2>
              {output
                ? "Wow, that was easy!"
                : "Enter a few details about the car you want to sell, and we'll take it from there!"}
            </h2>
          </div>
        </div>
        {!output && !isGenerating && (
          <InputForm
            VIN={VIN}
            setVIN={setVIN}
            modelYear={modelYear}
            setModelYear={setModelYear}
            mileage={mileage}
            setMileage={setMileage}
            additionalDetails={additionalDetails}
            setAdditionalDetails={setAdditionalDetails}
            price={price}
            setPrice={setPrice}
            output={output}
            setOutput={setOutput}
            setIsGenerating={setIsGenerating}
          />
        )}
        {isGenerating && <LoadingBar isGenerating={isGenerating} />}
        {!isGenerating && output && (
          <Output
            VIN={VIN}
            modelYear={modelYear}
            mileage={mileage}
            price={price}
            additionalDetails={additionalDetails}
            setIsGenerating={setIsGenerating}
            output={output}
            setOutput={setOutput}
            reset={reset}
          />
        )}
      </div>
    </div>
  );
}

export default Cars;
