import * as bootstrap from "bootstrap";
import { useEffect } from "react";

import generate from "../utils/generate";

function Output({
  VIN,
  modelYear,
  mileage,
  price,
  additionalDetails,
  setIsGenerating,
  output,
  setOutput,
  reset,
}) {
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div className="output-container">
      <textarea
        value={output}
        onChange={(e) => setOutput(e.target.value)}
        id="output-textarea"
      ></textarea>
      <br />
      <div className="row justify-content-around">
        <div className="output-button-container d-flex col-8 justify-content-around">
          <button
            type="button"
            className="output-button btn btn-danger"
            onClick={reset}
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-title="reset"
          >
            <i className="bi bi-x-circle output-icon"></i>
          </button>
          <button
            className="btn btn-danger output-button"
            onClick={(e) =>
              generate(
                e,
                VIN,
                modelYear,
                mileage,
                price,
                additionalDetails,
                setIsGenerating,
                output,
                setOutput
              )
            }
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-title="regenerate"
          >
            <i className="bi bi-arrow-repeat output-icon"></i>
          </button>
          <button
            className="btn btn-danger output-button"
            onClick={() => {
              navigator.clipboard.writeText(
                document.getElementById("output-textarea").value
              );
            }}
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-title="copy to clipboard"
          >
            <i className="bi bi-clipboard-plus output-icon"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Output;
