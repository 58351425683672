import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import generate from "../utils/generate";
import { setRedirectAfterLogin } from "../../../../userSlice";

function InputForm({
  VIN,
  setVIN,
  modelYear,
  setModelYear,
  mileage,
  setMileage,
  additionalDetails,
  setAdditionalDetails,
  price,
  setPrice,
  setIsGenerating,
  output,
  setOutput,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.user.loggedInUser);

  return (
    <div className="input-form-container d-flex justify-content-center mb-5">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!loggedInUser) {
            console.log("No logged in user. Redirecting to login.");
            dispatch(setRedirectAfterLogin("/cars"));
            navigate("/login");
          } else
            generate(
              e,
              VIN,
              modelYear,
              mileage,
              price,
              additionalDetails,
              setIsGenerating,
              output,
              setOutput
            );
        }}
      >
        <div className="form-field form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="VIN-input"
            onChange={(e) => setVIN(e.target.value)}
            required={true}
            placeholder="Enter VIN"
            value={VIN}
            maxLength="18"
          />
          <label htmlFor="VIN-input">VIN:</label>
        </div>
        <div className="form-field form-floating mb-3">
          <input
            type="number"
            min="1900"
            max="2024"
            step="1"
            className="form-control"
            id="model-year-input"
            onChange={(e) => setModelYear(e.target.value)}
            placeholder="Enter Model Year"
            value={modelYear}
          />
          <label htmlFor="model-year-input">
            Model year (required for pre-1980):
          </label>
        </div>
        <div className="form-field form-floating mb-3">
          <input
            type="number"
            step="1"
            size="7"
            className="form-control"
            id="mileage-input"
            onChange={(e) => setMileage(e.target.value)}
            placeholder="Enter Mileage:"
            value={mileage}
          />
          <label htmlFor="mileage-input">Current mileage:</label>
        </div>
        <div className="form-field form-floating mb-3">
          <input
            type="number"
            className="form-control"
            id="price-input"
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Enter Price $:"
            value={price}
          />
          <label htmlFor="mileage-input">Price $:</label>
        </div>
        <div className="form-field mb-3 form-floating">
          <textarea
            id="additional-details-input"
            className="form-control"
            value={additionalDetails}
            placeholder="For example: new tires, extended warranty, one owner..."
            onChange={(e) => setAdditionalDetails(e.target.value)}
          ></textarea>
          <label htmlFor="additional-details-input">
            Want to add any additional details?:
          </label>
        </div>
        <input
          className="btn btn-danger border"
          type="submit"
          value="Generate Ad"
        ></input>
      </form>
    </div>
  );
}

export default InputForm;
