import { Auth } from "aws-amplify";
import { sha256 } from "js-sha256";

const API_URL =
  "https://oy1hu45rzh.execute-api.us-east-1.amazonaws.com/dev/log";

export default async function logResult(prompt, result, type) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const emailAddress = user.attributes.email;
    const hash = sha256.create();
    hash.update(emailAddress);
    const hashedEmailAddress = hash.hex();

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        app: "sandbox",
        user: hashedEmailAddress,
        prompt,
        result,
        type,
      }),
    });
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
