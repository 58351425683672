import { Helmet } from "react-helmet";

import SubSelectionTable from "../../subSelectionTable/subSelectionTable.component";

export default function SelectAccountType() {
  return (
    <div>
      <Helmet>
        <title>genny | select account type</title>
      </Helmet>
      <div className="container text-light text-center">
        <h1 className="mb-3">
          Thank you for using <span className="h1-genny">genny</span>!
        </h1>
        <h3>
          Now it's time to select your account plan. If you're new here, a free
          account will probably work just fine for a while.
        </h3>
        <hr />
        <SubSelectionTable />
      </div>
    </div>
  );
}
