import { Helmet } from "react-helmet";

import "./howTo.styles.scss";

function HowTo() {
  return (
    <div className="text-light text-center">
      <Helmet>
        <title>genny | how to</title>
      </Helmet>
      <div className="border border-secondary rounded mx-5 p-5">
        <h2 className="gradient-text mb-3">
          "The winning combo is going to be this artful interaction of AI and
          humans."
        </h2>
        <h4 className="text-light">
          -Dr. James Lester in the Wall Street Journal
        </h4>
      </div>
      <div className="container mt-4 text-start">
        <p className="fs-5">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In offering you the following tips, we
          humbly aspire to help you achieve the artful insertion of AI into your
          creative process. Genny is designed to augment, not replace, your
          human creativity.
        </p>
        <div className="quick-nav text-center">
          <div>
            <a href="#basics" className="link-info">
              The Basics
            </a>{" "}
            |{" "}
            <a href="#advanced" className="link-info">
              Advanced Topics
            </a>{" "}
            |{" "}
            <a href="#apps" className="link-info">
              Genny Apps
            </a>
          </div>
          <div>
            <a href="#troubleshooting" className="link-info">
              Troubleshooting
            </a>
          </div>
        </div>
        {/* ---------------------------------THE BASICS---------------------------- */}
        <div className="container border border-secondary rounded px-5 py-4 mt-3">
          <div className="fs-4" id="basics">
            The Basics:
          </div>
          <ul>
            <li className="my-2">
              <div>
                <a href="/howTo/genny101" className="link-info">
                  Genny 101: What Is a Prompt?
                </a>
              </div>
              <div>
                In this video, you'll learn what a prompt is and the central
                role that it plays in how{" "}
                <span className="h6-genny">genny</span> works.
              </div>
            </li>
            <li className="my-2">
              <div>
                <a href="/howTo/genny201" className="link-info">
                  Genny 201: Write Better Prompts
                </a>
              </div>
              <div>
                In this video, you'll learn how to write better prompts, leading
                you to better results and getting you there faster.
              </div>
            </li>
            <li className="my-2">
              <div>
                <a href="/howTo/genny301" className="link-info">
                  Genny 301: Varrying Results, One of Genny's Many Superpowers
                </a>
              </div>
              <div>
                In this video, you'll learn how to write better prompts, leading
                you to better results and getting you there faster.
              </div>
            </li>
            <li className="mt-2">
              <div>
                <a href="/howTo/genny401" className="link-info">
                  Genny 401: Prompt Chaining{" "}
                </a>
              </div>
              <div>
                In this video, you'll learn how to chain your prompts together
                to quickly progress from idea to outline to first draft.
              </div>
            </li>
          </ul>
        </div>
        {/* ---------------------------------ADVANCED TOPICS---------------------------- */}
        <div className="container border border-secondary rounded px-5 py-4 mt-3">
          <div className="fs-4" id="advanced">
            Advanced Topics:
          </div>
          <ul>
            {/* <li className="my-2">
              <div>
                <a href="/howTo/genny101">Genny 101: What Is a Prompt?</a>
              </div>
              <div>
                In this video, you'll learn what a prompt is and the central
                role that it plays in how{" "}
                <span className="brand-color">genny</span> works.
              </div>
            </li> */}
          </ul>
        </div>
        {/* ---------------------------------GENNY APPS---------------------------- */}
        <div className="container border border-secondary rounded px-5 py-4 mt-3">
          <div className="fs-4" id="apps">
            Genny Apps:
          </div>
          <ul className="mt-3 fs-5">
            <li>Genny for Chrome:</li>
            <ul className="fs-6">
              <li className="my-2">
                <div>
                  <a href="/howTo/chromeTutorial" className="link-info">
                    Quickstart Guide
                  </a>
                </div>
                <div>A short tutorial on how to use Genny for Chrome</div>
              </li>
              <li className="my-2">
                <div>
                  <a href="/howTo/chromeInstall" className="link-info">
                    Installation Instructions
                  </a>
                </div>
                <div>Quickly and easily install Genny for Chrome.</div>
              </li>
            </ul>
            <li>Genny for Word:</li>
            <ul className="fs-6">
              <li className="my-2">
                <div>
                  <a href="/howTo/gennyForWord" className="link-info">
                    Quickstart Guide
                  </a>
                </div>
                <div>A short tutorial on how to use Genny for Word.</div>
              </li>
            </ul>
          </ul>
        </div>
        {/* ---------------------------------TROUBLESHOOTING---------------------------- */}
        <div className="container border border-secondary rounded px-5 py-4 mt-3 mb-5">
          <div className="fs-4" id="troubleshooting">
            Troubleshooting:
          </div>
          <ul>
            {/* <li className="my-2">
              <div>
                <a href="/howTo/genny101">Genny 101: What Is a Prompt?</a>
              </div>
              <div>
                In this video, you'll learn what a prompt is and the central
                role that it plays in how{" "}
                <span className="brand-color">genny</span> works.
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HowTo;
