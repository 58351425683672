import { Auth } from "aws-amplify";
import { sha256 } from "js-sha256";

const API_ENDPOINT =
  "https://oy1hu45rzh.execute-api.us-east-1.amazonaws.com/dev/get-user";

async function getUser(stripeId) {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (!cognitoUser) return false;
    const cognitoUsername = cognitoUser.username;
    const email = cognitoUser.attributes.email;
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const hash = sha256.create();
    hash.update(email);
    const hashedEmail = hash.hex();
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        email: hashedEmail,
        stripeId: stripeId,
        cognitoUsername,
      }),
    });
    const { userData } = await response.json();
    return userData;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export default getUser;
