import { Auth } from "aws-amplify";
import { sha256 } from "js-sha256";

import { store } from "../../store/index";

const API_URL =
  "https://oy1hu45rzh.execute-api.us-east-1.amazonaws.com/dev/run-prompt";

async function runPrompt(prompt, app = "unknown", temperature = 0.7) {
  // USED TO TEST APP WITHOUT WASTING GPT CALLS
  if (prompt === "test") {
    console.log("in if");
    const testData = {
      message: {
        choices: [{ text: "Here is your test message, sir." }],
      },
    };
    console.log(testData);
    return testData;
  }

  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  const emailAddress = user.attributes.email;
  const hash = sha256.create();
  hash.update(emailAddress);
  const hashedEmailAddress = hash.hex();

  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sourceApp: app,
      user: hashedEmailAddress,
      model: "text-davinci-003",
      prompt: prompt,
      max_tokens: 1250,
      temperature: temperature,
      log: "false",
    }),
  });
  const data = await response.json();
  console.log(data);
  return data;
}

export default runPrompt;
