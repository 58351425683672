import { Auth } from "aws-amplify";
import { sha256 } from "js-sha256";

import deductPrompt from "../../../../utilties/users/deductPrompt";

const SOCKET_URL =
  "wss://3x0qr9eau0.execute-api.us-east-1.amazonaws.com/production";

export default async function sandboxRunPromptStream(
  targetEl,
  prompt,
  temperature = 0.7
) {
  return new Promise(async (res, rej) => {
    const user = await Auth.currentAuthenticatedUser();
    const emailAddress = user.attributes.email;
    const hash = sha256.create();
    hash.update(emailAddress);
    const hashedEmailAddress = hash.hex();
    let results = [];

    async function buildGenerateResult() {
      let result = "";
      for (let i = 0; i < results.length; i++) {
        for (let j = 0; j < results.length; j++) {
          if (results[j].counter === i) {
            result += results[j].data;
            targetEl.value = result.trim();
          }
        }
      }
      return result;
    }

    try {
      const gennySocket = new WebSocket(SOCKET_URL);
      gennySocket.onopen = () => {
        gennySocket.send(
          JSON.stringify({
            action: "runPrompt",
            data: {
              prompt,
              user: hashedEmailAddress,
              maxTokens: 1250,
              model: "text-davinci-003",
              temperature,
            },
          })
        );

        gennySocket.onmessage = async (e) => {
          const message = await JSON.parse(e.data);
          //console.log(message);
          if (!message.data.includes("[DONE]")) {
            results.push(message);
            buildGenerateResult(results);
          }
          if (message.data.includes("[DONE]")) {
            deductPrompt();
            setTimeout(async () => {
              const resultsObj = structuredClone(message);
              resultsObj.data = await buildGenerateResult(true);
              console.log("final:", resultsObj);
              gennySocket.close();
              res(resultsObj);
            }, 500);
          }
        };
        setTimeout(() => {
          gennySocket.close();
          rej("timed out waiting for server");
        }, 60 * 1000);
      };
    } catch (error) {
      rej(error);
    }
  });
}
