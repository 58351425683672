import { Helmet } from "react-helmet";

import VideoPage from "../../videoPage.component";

function ChromeTutorial() {
  return (
    <div>
      <Helmet>
        <title>genny | chrome tutorial</title>
      </Helmet>
      <VideoPage
        title="Chrome Extension: Quickstart Guide"
        description={
          <div>
            A short tutorial on how to use the{" "}
            <span className="h3-genny">genny</span> Chrome extension.
          </div>
        }
        embedId="QNAbmVrY-_0"
      />
    </div>
  );
}

export default ChromeTutorial;
