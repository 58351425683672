import { useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import { useAnalytics } from "use-analytics";
import { useDispatch, useSelector } from "react-redux";

import { login, logout, setRedirectAfterLogin } from "./userSlice";

import Cars from "./components/pages/cars/cars.component";
import ChromeTutorial from "./components/pages/howTo/videos/gennyApps/chromeExtension/chromeTutorial.component";
import ChromeInstall from "./components/pages/howTo/videos/gennyApps/chromeExtension/chromeInstall.component";
import CloudServicesAgreement from "./components/pages/legal/cloudServicesAgreement.component";
import Download from "./components/pages/download/download.component";
import Footer from "./components/footer/Footer";
import Genny101 from "./components/pages/howTo/videos/theBasics/genny101.component";
import Genny201 from "./components/pages/howTo/videos/theBasics/genny201.component";
import Genny301 from "./components/pages/howTo/videos/theBasics/genny301.component";
import Genny401 from "./components/pages/howTo/videos/theBasics/genny401.component";
import GennyForExcelHowTo from "./components/pages/howTo/videos/gennyApps/gennyForExcel/gennyForExcelHowTo.component";
import GennyForWordHowTo from "./components/pages/howTo/videos/gennyApps/gennyForWord/gennyForWordHowTo.component";
import GennyNavbar from "./components/nav/gennyNavbar.component";
import Home from "./components/pages/home/home.component";
import HowTo from "./components/pages/howTo/howTo.component";
import Login from "./components/pages/login/login.component";
import NotFound from "./components/pages/notFound/notFound.component";
import OutOfPrompts from "./components/pages/outOfPrompts/outOfPrompts.component";
import PrivacyPolicy from "./components/pages/legal/privacyPolicy.component";
import Pricing from "./components/pages/pricing/pricing.component";
import Recipes from "./components/pages/recipes/recipes.component";
import Sandbox from "./components/pages/sandbox/sandbox.component";
import SelectAccountType from "./components/pages/selectAccountType/selectAccountType.component";
import sendSessionToExtension from "./utilties/sendSessionToExtension";
import setUserState from "./utilties/users/setUserState";
import TermsOfUse from "./components/pages/legal/termsOfUse.component";
import ThankYou from "./components/pages/thankYou/thankYou.component";
import Upgrade from "./components/pages/upgrade/upgrade.component";

function App() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const { loggedInUser } = useSelector((state) => state.user);

  async function loginHandler(user) {
    if (user) {
      try {
        dispatch(login());
        const email = user.attributes.email;
        const userData = await setUserState(email);
        // if (
        //   userData &&
        //   !userData.subscription &&
        //   location.pathname !== "/selectAccountType"
        // )
        //   navigate("/selectAccountType");
        sendSessionToExtension();
      } catch (error) {
        console.log(error);
      }
    }
  }

  if (!loggedInUser) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        loginHandler(user);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    Hub.listen("auth", async (data) => {
      try {
        // eslint-disable-next-line default-case
        switch (data.payload.event) {
          case "signIn":
            const signInUser = await Auth.currentAuthenticatedUser();
            loginHandler(signInUser);
            break;
          case "signUp":
            console.log("signUp!");
            //dispatch(setRedirectAfterLogin("/selectAccountType"));
            break;
          case "signOut":
            console.log("user signed out");
            dispatch(logout);
            break;
          case "signIn_failure":
            console.log("user sign in failed");
            break;
        }
      } catch (error) {
        console.log(error);
      }
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    analytics.page();
    //eslint-disable-next-line
  }, [location]);

  return (
    <div className="bg-dark app-container">
      {/* <h1 id="maintenance">Genny is down for maintenance.😢</h1> */}
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          path="/"
          element={
            <div>
              <div className="d-flex flex-column min-vh-100">
                <GennyNavbar />
                <Outlet />
              </div>
              <Footer />
            </div>
          }
        >
          <Route index element={<Home />} />
          <Route path="cars" element={<Cars />} />
          <Route path="download" element={<Download />} />
          <Route path="howTo" element={<HowTo />} />
          <Route path="howTo/chromeInstall" element={<ChromeInstall />} />
          <Route path="howTo/chromeTutorial" element={<ChromeTutorial />} />
          <Route path="howTo/genny101" element={<Genny101 />} />
          <Route path="howTo/genny201" element={<Genny201 />} />
          <Route path="howTo/genny301" element={<Genny301 />} />
          <Route path="howTo/genny401" element={<Genny401 />} />
          <Route path="howTo/gennyForExcel" element={<GennyForExcelHowTo />} />
          <Route path="howTo/gennyForWord" element={<GennyForWordHowTo />} />
          <Route
            path="legal/cloudServicesAgreement"
            element={<CloudServicesAgreement />}
          />
          <Route path="legal/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="legal/termsOfUse" element={<TermsOfUse />} />
          <Route path="login" element={<Login />} />
          <Route path="outOfPrompts" element={<OutOfPrompts />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="recipes" element={<Recipes />} />
          <Route path="sandbox" element={<Sandbox />} />
          <Route path="selectAccountType" element={<SelectAccountType />} />
          <Route path="thankYou" element={<ThankYou />} />
          <Route path="upgrade" element={<Upgrade />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
