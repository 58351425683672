import VideoHeader from "./videoHeader.component";
import VideoNav from "./videoNav.component";
import YoutubeEmbed from "../../../youTubeEmbed/youTubeEmbed.component";

function VideoPage({
  title,
  description,
  backTitle = null,
  backHref = null,
  nextTitle = null,
  nextHref = null,
  embedId,
}) {
  return (
    <div className="container text-center text-light">
      <VideoHeader title={title} description={description} />
      <VideoNav
        backTitle={backTitle}
        backHref={backHref}
        nextTitle={nextTitle}
        nextHref={nextHref}
      />
      {embedId && (
        <div className="mx-5 mt-3 mb-5">
          <YoutubeEmbed embedId={embedId} />
        </div>
      )}
    </div>
  );
}

export default VideoPage;
