import { store } from "../../../store";
import { setActiveVoice } from "../../pages/sandbox/sandboxSlice";

function voiceSelectOnChangeHandler(event) {
  const voiceURI = event.target.value;
  window.localStorage.setItem("genny-settings-voice", voiceURI);
  store.dispatch(setActiveVoice(voiceURI));
}

export default voiceSelectOnChangeHandler;
