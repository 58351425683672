import { store } from "../../store";

export default function checkAccount(app = null) {
  const state = store.getState();
  const { rejectedScreens, subscription, mode } = state.user;
  if (!subscription) {
    window.location.href = "/selectAccountType";
    return false;
  }
  if (subscription.monthlyPromptsRemaining < 1) {
    window.location.href = "/outOfPrompts";
    return false;
  }
  if (rejectedScreens > 2 && mode !== "god") {
    console.error("Too many failed screens!");
    return false;
  }
  return true;
}
