import { useDispatch, useSelector } from "react-redux";

import buildPrompt from "./utils/buildPrompt";
import EmailRecipesForm from "./emailRecipesForm.component";
import Loading from "./loading.component";
import { replaceMeal } from "../recipesSlice";
import runPrompt from "../../../../utilties/prompts/runPrompt";

function Output() {
  const dispatch = useDispatch();
  const { meals, isGenerating } = useSelector((state) => state.recipes);

  async function changeClickHandler(indexToReplace) {
    const prompt = await buildPrompt();
    dispatch(
      replaceMeal({ target: indexToReplace, meal: { name: "being replaced" } })
    );
    let replacementMeal;
    let loops = 0;
    while (!replacementMeal && loops < 10) {
      try {
        const data = await runPrompt(prompt, "recipes", 0.9);
        const recipeObject = await JSON.parse(
          data.message.choices[0].text.replace(/"(?:""|[^"])+"/g, function (x) {
            return x.replace(/[^\S\r\n]*[\n\r]\s*/g, "[RETURN]");
          })
        );
        replacementMeal = recipeObject;
      } catch (error) {
        console.log(error);
      }
      loops++;
    }
    dispatch(replaceMeal({ target: indexToReplace, meal: replacementMeal }));
  }

  return (
    <div id="recipe-output-container">
      {meals.map((meal, index) => {
        console.log("meal:", meal);
        console.log("index:", index);
        if (meal.name === "being replaced") {
          return (
            <div key="being-replaced">
              <Loading />
            </div>
          );
        } else
          return (
            <div key={`recipe-output-${index}`}>
              <div className="single-recipe-container p-3 my-3">
                <h1>
                  Meal {index + 1}: {meal.name}
                </h1>
                <hr />
                <h2>Ingredients:</h2>
                <ul>
                  {meal.ingredients.map((ingredient, index2) => {
                    return (
                      <li key={`recipe-${index}-ingredient-${index2}`}>
                        {ingredient.name}: {ingredient.amount}
                      </li>
                    );
                  })}
                </ul>
                <h2>Directions:</h2>
                <p>{meal.directions.replaceAll("[RETURN]", "\n")}</p>
                <button
                  className="btn btn-info"
                  onClick={() => changeClickHandler(index)}
                >
                  I don't like this.
                  <br />
                  Try again.
                </button>
              </div>
              <br />
            </div>
          );
      })}
      {/* {!isGenerating && <EmailRecipesForm />} */}
    </div>
  );
}

export default Output;
