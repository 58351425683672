import { Helmet } from "react-helmet";

import SubSelectionTable from "../../subSelectionTable/subSelectionTable.component";

export default function Upgrade() {
  return (
    <div>
      <Helmet>
        <title>genny | upgrade</title>
      </Helmet>
      <div id="upgrade-container" className="text-center">
        <h1 className="text-light mx-3 mb-5">
          Get more out of <span className="h1-genny">genny</span> with an
          upgraded account!
        </h1>
        <SubSelectionTable showFree={false} />
      </div>
    </div>
  );
}
