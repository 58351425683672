import { Helmet } from "react-helmet";

import SubSelectionTable from "../../subSelectionTable/subSelectionTable.component";

export default function Pricing() {
  return (
    <div>
      <Helmet>
        <title>genny | pricing</title>
      </Helmet>
      <div id="pricing-page-container" className="text-center">
        <h1 className="gradient-text headline">pricing</h1>
        <SubSelectionTable />
      </div>
    </div>
  );
}
