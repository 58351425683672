import { Auth } from "aws-amplify";

import setUserState from "../users/setUserState";
import { store } from "../../store";

const API_ENDPOINT =
  "https://oy1hu45rzh.execute-api.us-east-1.amazonaws.com/dev/create-checkout";

export default async function createStripePortal() {
  try {
    const state = store.getState();
    const customer = state.user.stripeId;
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (!cognitoUser) throw new Error("no current authenticated user");
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        customer,
        requestType: "portal",
      }),
    });
    const data = await response.json();
    return data.portalUrl;
  } catch (error) {
    console.log(error);
    return error;
  }
}
