const API_ENDPOINT = "https://gennyusers.azurewebsites.net/api/emailAlert";

export default async function emailAlert({ subject, emailBody }) {
  const response = await fetch(API_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subject,
      emailBody,
    }),
  });
  console.log(response);
}
