import { Helmet } from "react-helmet";

import appSourceBadge from "./assets/MS_AppSource.png";
import chromeWebStoreBadge from "./assets/iNEddTyWiMfLSwFD6qGq.png";
import "./download.styles.scss";
import YoutubeEmbed from "../../youTubeEmbed/youTubeEmbed.component";

function Download() {
  return (
    <div className="text-center text-light mb-5">
      <Helmet>
        <title>Genny | Download</title>
      </Helmet>
      <h1 className="gradient-text headline mb-3">Download</h1>
      <div className="container-fluid text-light px-5">
        <div className="text-start mx-4">
          <h2>Currrent releases:</h2>
          <ul className="fs-4">
            <li>
              <a href="#chrome" className="link-info">
                Genny for Chrome (v 0.3.1)
              </a>
            </li>
            <li>
              <a href="#word" className="link-info">
                Genny for Word (v 1.0.0)
              </a>
            </li>
          </ul>
        </div>
        <hr className="m-5" />
        <div id="chrome">
          <h3 className="text-start ms-4 mt-3">
            Genny for Chrome - bringing the power of{" "}
            <span className="h3-genny">genny</span> to your favorite web apps.
          </h3>
        </div>
        <a
          href="https://chrome.google.com/webstore/detail/genny/fchkabnafbbcfemenkejfmfogbnmgokj"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={chromeWebStoreBadge}
            alt="chrome web store badge"
            className="mt-3 app-store-badge"
          />
        </a>
        <div className="container-fluid">
          <div className="row align-items-end justify-content-around px-5">
            <div className="col col-12 col-lg-6 p-5">
              <h4 className="text-start">Installation instructions:</h4>
              <YoutubeEmbed embedId="_dW_OeYC-AU" />
            </div>
            <div className="col col-12 col-lg-6 p-5">
              <h4 className="text-start">Quickstart guide:</h4>
              <YoutubeEmbed embedId="Bh0A3wy4LD8" />
            </div>
          </div>
        </div>
        <hr className="m-5" />
        <div id="word">
          <h3 className="text-start ms-4 mt-3">
            Genny for Word - bringing the power of{" "}
            <span className="h3-genny">genny</span> to your favorite word
            processor.
          </h3>
        </div>
        <a
          href="https://appsource.microsoft.com/en-us/product/office/WA200005175?tab=Overview"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={appSourceBadge}
            alt="app source badge"
            className="my-3 app-store-badge"
          />
        </a>
        <div className="container-fluid">
          <div className="row align-items-start justify-content-around px-5">
            <div className="col col-12 col-lg-6 p-5">
              <h4 className="text-start">
                Installation instructions (Word Online):
              </h4>
              <YoutubeEmbed embedId="NQFLYP9nztw" />
              <h4 className="text-start mt-4">
                Installation instructions (Word Desktop):
              </h4>
              <YoutubeEmbed embedId="Hh6EPmQ6ZPE" />
            </div>
            <div className="col col-12 col-lg-6 p-5">
              <h4 className="text-start">Quickstart guide:</h4>
              <YoutubeEmbed embedId="bM73Q-XsEcg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
