import { useDispatch } from "react-redux";

import AudioControls from "./audioControls.components";
import clickToTweet from "../../../utilties/clickToTweet";
import generate from "./utilities/generate";
import SandboxButton from "./sandboxButton.component";
import { setInputsArray, setNumInputs } from "./sandboxSlice";
import "./sandboxInput.styles.scss";

function SandboxInput({ number, utterance }) {
  const dispatch = useDispatch();

  function resetOne(event) {
    const targetNum = event.target.id.slice(-1);
    console.log(targetNum);
    const targetEl = document.getElementById(`prompt-input-${targetNum}`);
    targetEl.value = "";
  }

  function resetAll() {
    dispatch(setNumInputs(1));
    dispatch(setInputsArray([1]));
    const targetEl = document.getElementById("prompt-input-1");
    targetEl.value = "";
  }

  return (
    <div id={`input-${number}-container`}>
      <textarea
        className="prompt-input"
        id={`prompt-input-${number}`}
        name={number === 0 ? "prompt" : ""}
        placeholder={`Tell genny what you want to do, e.g., "Write a tweet about this awesome new AI app that I discovered."`}
      />
      <SandboxButton
        buttonText="generate"
        clickFunction={generate}
        number={number}
      />
      {number > 1 && (
        <SandboxButton
          buttonText="clipboard"
          clickFunction={() => {
            navigator.clipboard.writeText(
              document.getElementById(`prompt-input-${number}`).value
            );
          }}
        />
      )}
      <AudioControls number={number} utterance={utterance} />
      {number > 1 && (
        <SandboxButton
          buttonText=""
          clickFunction={(e) => {
            e.preventDefault();
            const baseText = document.getElementById(
              `prompt-input-${number}`
            ).value;
            clickToTweet(baseText);
          }}
        />
      )}
      <SandboxButton
        buttonText="reset"
        clickFunction={resetOne}
        number={number}
      />

      {number > 1 && (
        <SandboxButton buttonText="reset all" clickFunction={resetAll} />
      )}
    </div>
  );
}

export default SandboxInput;
