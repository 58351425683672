import { Analytics } from "analytics";
import googleAnalytics from "@analytics/google-analytics";

const analytics = Analytics({
  app: "genny web",
  plugins: [
    // {
    //   name: "genny-analytics-plugin",
    //   page: ({ payload }) => {
    //     console.log("page view fired", payload);
    //   },
    //   track: ({ payload }) => console.log("track event payload", payload),
    // },
    googleAnalytics({
      measurementIds: ["G-N7MQV03N4R"],
    }),
  ],
});

export default analytics;
