import { store } from "../../../../../store/index";

async function buildPrompt() {
  const { meals, numberOfDiners } = store.getState().recipes;
  const { dairy, eggs, fish, shellfish, treeNut, peanut, gluten, soybean } =
    store.getState().recipes.allergies;
  const {
    vegetarian,
    vegan,
    pescatarian,
    "pork-free": porkFree,
    kosher,
  } = store.getState().recipes.special;

  let prompt = `Generate a meal recipe in JSON format ("name": "", "ingredients": [{"name": "", "amount": ""}], "directions": "") for ${numberOfDiners} people`;
  if (dairy) prompt += ", with a dairy allegy";
  if (eggs) prompt += ", with an eggs allergy";
  if (fish) prompt += ", with a fish allergy";
  if (shellfish) prompt += ", with a shellfish allergy";
  if (treeNut) prompt += ", with a tree nut allergy";
  if (peanut) prompt += ", with a peanut allergy";
  if (gluten) prompt += ", with a gluten allergy";
  if (soybean) prompt += ", with a soybean allergy";
  if (vegetarian) prompt += ", who are vegetarian";
  if (vegan) prompt += ", who are vegan";
  if (pescatarian) prompt += ", who are pescatarian";
  if (porkFree) prompt += ", who eat a pork-free diet";
  if (kosher) prompt += ", who eat a kosher diet";
  if (meals && meals.length > 0) {
    for (let i = 0; i < meals.length; i++) {
      prompt += `, and is not ${meals[i].name}`;
    }
  }
  prompt += ".";
  return prompt;
}

export default buildPrompt;
