import "./sandboxButton.styles.css";

function getButtonText(buttonText) {
  switch (buttonText) {
    case "":
      return (
        <div>
          <i className="bi bi-twitter"></i>
        </div>
      );
    case "clipboard":
      return (
        <div>
          <i className="bi bi-clipboard2-plus-fill"></i>
        </div>
      );
    default:
      return buttonText;
  }
}

function SandboxButton({ buttonText, clickFunction, number }) {
  return (
    <div className="sandbox-btn-container">
      <button
        className={`sandbox-btn ${
          buttonText === "generate" ? "brand-color" : ""
        } ${
          buttonText === "" || buttonText === "clipboard" ? "tweet-btn" : ""
        }`}
        id={`${buttonText}-btn-${number}`}
        onClick={clickFunction}
      >
        {getButtonText(buttonText)}
      </button>
    </div>
  );
}

export default SandboxButton;
