import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeAudio: null,
  activeVoice: null,
  inputsArray: [1],
  numInputs: 1,
  pitch: 1,
  rate: 1,
};

export const sandboxSlice = createSlice({
  name: "sandbox",
  initialState,
  reducers: {
    setActiveAudio: (state, action) => {
      state.activeAudio = action.payload;
    },
    setActiveVoice: (state, action) => {
      state.activeVoice = action.payload;
    },
    setInputsArray: (state, action) => {
      state.inputsArray = action.payload;
    },
    setNumInputs: (state, action) => {
      state.numInputs = action.payload;
    },
    setPitch: (state, action) => {
      state.pitch = action.payload;
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
  },
});

export const {
  setActiveAudio,
  setActiveVoice,
  setInputsArray,
  setNumInputs,
  setPitch,
  setRate,
} = sandboxSlice.actions;

export default sandboxSlice.reducer;
