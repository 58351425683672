import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import generateMeals from "./utils/generateMeals";

import {
  updateNumber,
  checkAllergy,
  uncheckAllergy,
  checkSpecial,
  uncheckSpecial,
} from "../recipesSlice";
import { resetMeals } from "../recipesSlice";
import { setRedirectAfterLogin } from "../../../../userSlice";

const defaultsData = window.localStorage.getItem("recipeDefaults");
let defaults = {};
if (defaultsData) {
  defaults = JSON.parse(defaultsData);
}

function InitialInput() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.loggedInUser);
  const { numberOfDiners, numberOfMeals } = useSelector(
    (state) => state.recipes
  );
  const allergies = useSelector((state) => state.recipes.allergies);
  const specials = useSelector((state) => state.recipes.special);

  const navigate = useNavigate();

  function onSubmitHandler(e) {
    e.preventDefault();
    if (!user) {
      dispatch(setRedirectAfterLogin("/recipes"));
      navigate("/login");
    } else {
      dispatch(resetMeals());
      try {
        generateMeals();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div id="recipes-initial-input-container" className="p-3">
      <form>
        {/* ---------------------------NUMBER OF DINERS------------------------------- */}
        <div>
          <label htmlFor="eaters-quantity-input" className="form-label">
            How many are you cooking for?
          </label>
          <input
            type="number"
            className="form-control"
            id="eaters-quantity-input"
            min="1"
            max="10"
            value={numberOfDiners}
            onChange={(e) => {
              dispatch(
                updateNumber({
                  target: "numberOfDiners",
                  value: e.target.value,
                })
              );
              defaults.numberOfDiners = e.target.value;
              window.localStorage.setItem(
                "recipeDefaults",
                JSON.stringify(defaults)
              );
            }}
          />
        </div>
        {/* ---------------------------NUMBER OF MEALS------------------------------- */}
        <div>
          <label htmlFor="meals-quantity-input" className="form-label">
            How many meals would you like to plan?
          </label>
          <input
            type="number"
            className="form-control"
            id="meals-quantity-input"
            min="1"
            max="7"
            value={numberOfMeals}
            onChange={(e) => {
              dispatch(
                updateNumber({ target: "numberOfMeals", value: e.target.value })
              );
              defaults.numberOfMeals = e.target.value;
              window.localStorage.setItem(
                "recipeDefaults",
                JSON.stringify(defaults)
              );
            }}
          />
        </div>
        <div id="allergies-and-special-container">
          {/* ---------------------------ALLERIGES------------------------------- */}
          <div id="allergies-container" className="m-2 p-2">
            <div>Allergies?</div>
            {Object.keys(allergies).map((allergy, index) => {
              return (
                <div key={`allergy-${index}`}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id={`${allergy}-allergy-checkbox`}
                    checked={allergies[allergy]}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(checkAllergy(allergy));
                        defaults[`${allergy}Allergy`] = "true";
                      } else {
                        dispatch(uncheckAllergy(allergy));
                        defaults[`${allergy}Allergy`] = "false";
                      }
                      window.localStorage.setItem(
                        "recipeDefaults",
                        JSON.stringify(defaults)
                      );
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${allergy}-allergy-checkbox`}
                  >
                    &nbsp;{allergy}
                  </label>
                </div>
              );
            })}
          </div>
          {/* ---------------------------SPECIAL DIET ------------------------------- */}
          <div id="special-container" className="m-2 p-2 align-top">
            <div>Special Diet?</div>
            {Object.keys(specials).map((special, index) => {
              return (
                <div key={`special-${index}`}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id={`${special}-special-checkbox`}
                    checked={specials[special]}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(checkSpecial(special));
                        defaults[`${special}Special`] = "true";
                      } else {
                        dispatch(uncheckSpecial(special));
                        defaults[`${special}Special`] = "false";
                      }
                      window.localStorage.setItem(
                        "recipeDefaults",
                        JSON.stringify(defaults)
                      );
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${special}-special-checkbox`}
                  >
                    &nbsp;{special}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <button className="btn btn-info" onClick={onSubmitHandler}>
          Generate Meal Plan
        </button>
      </form>
    </div>
  );
}

export default InitialInput;
