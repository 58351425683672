import { Auth } from "aws-amplify";

import { store } from "../../store";

const priceApiIds = {
  monthly: {
    free: "price_1MTtbhFPlPwyMXlqgSJ5TrP0",
    creator: "price_1MTtc1FPlPwyMXlql05QjzZz",
    pro: "price_1MTtbsFPlPwyMXlq2YcasQos",
  },
  annual: {
    free: "price_1MTtbhFPlPwyMXlqWnnTRirA",
    creator: "price_1MTtc1FPlPwyMXlq3skfKyNN",
    pro: "price_1MTtbsFPlPwyMXlqXhj2CiHw",
  },
};

const API_ENDPOINT =
  "https://oy1hu45rzh.execute-api.us-east-1.amazonaws.com/dev/create-checkout";

export default async function goToStripeCheckout(term, plan) {
  try {
    const state = store.getState();
    const customer = state.user.stripeId;
    //if (!customer) throw new Error("no stripeId");
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (!cognitoUser) throw new Error("no current authenticated user");
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    //call endpoint with price
    const requestBody = {
      price: priceApiIds[term][plan],
      customer,
      email: cognitoUser.attributes.email,
    };
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data.checkoutUrl;
  } catch (error) {
    console.log(error);
    return error;
  }
}
