import { useEffect, useState } from "react";

import figlet from "figlet";
import dancingFont from "figlet/importable-fonts/Dancing Font.js";

figlet.parseFont("Dancing Font", dancingFont);

function Loading() {
  const [loadingText, setLoadingText] = useState();

  useEffect(() => {
    async function dance() {
      let loops = 0;
      let transitionMs = 1500;
      while (loops < 100) {
        figlet.text(
          "genny",
          {
            font: "Dancing Font",
          },
          function (err, data) {
            setLoadingText(data);
          }
        );
        await new Promise((r) => setTimeout(r, transitionMs));
        figlet.text(
          "is",
          {
            font: "Dancing Font",
          },
          function (err, data) {
            setLoadingText(data);
          }
        );
        await new Promise((r) => setTimeout(r, transitionMs));
        figlet.text(
          "on",
          {
            font: "Dancing Font",
          },
          function (err, data) {
            setLoadingText(data);
          }
        );
        await new Promise((r) => setTimeout(r, transitionMs));
        figlet.text(
          "the",
          {
            font: "Dancing Font",
          },
          function (err, data) {
            setLoadingText(data);
          }
        );
        await new Promise((r) => setTimeout(r, transitionMs));
        figlet.text(
          "job!",
          {
            font: "Dancing Font",
          },
          function (err, data) {
            setLoadingText(data);
          }
        );
        await new Promise((r) => setTimeout(r, transitionMs));
        loops++;
      }
    }

    dance();
  }, []);

  return (
    <div className="loading-container p-1 m-3">
      <pre>{loadingText}</pre>
    </div>
  );
}

export default Loading;
