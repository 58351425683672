import { configureStore } from "@reduxjs/toolkit";

import recipesReducer from "../components/pages/recipes/recipesSlice";
import sandboxReducer from "../components/pages/sandbox/sandboxSlice";
import userReducer from "../userSlice";

export const store = configureStore({
  reducer: {
    recipes: recipesReducer,
    sandbox: sandboxReducer,
    user: userReducer,
  },
});
