import { Helmet } from "react-helmet";

import "./howTo.styles.scss";
import YoutubeEmbed from "../../../../../youTubeEmbed/youTubeEmbed.component";

export default function GennyForExcelHowTo() {
  return (
    <div className="text-light text-center">
      <Helmet>
        <title>Genny | Genny for Excel</title>
      </Helmet>
      <div className="border border-secondary rounded mx-5 p-2">
        <h1 className="gradient-text">Genny for Excel: Support</h1>
      </div>
      <div className="container-fluid">
        <div className="row align-items-start justify-content-around px-5">
          {/* <div className="col col-12 col-lg-6 p-5">
            <h4 className="text-start">
              Installation instructions (Word Online):
            </h4>
            <YoutubeEmbed embedId="NQFLYP9nztw" />
            <h4 className="text-start mt-4">
              Installation instructions (Word Desktop):
            </h4>
            <YoutubeEmbed embedId="Hh6EPmQ6ZPE" />
          </div> */}
          <div className="col col-12 col-lg-6 p-5">
            <h4 className="text-start">Quickstart guide:</h4>
            <YoutubeEmbed embedId="wGbRHi1arzQ" />
          </div>
        </div>
        <h3 className="mb-5">
          Don't see what you need?
          <br />
          <a href="mailto:support@gennyapp.com">Email us.</a>
        </h3>
      </div>
    </div>
  );
}
