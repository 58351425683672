import { Auth } from "aws-amplify";

const API_ENDPOINT =
  "https://oy1hu45rzh.execute-api.us-east-1.amazonaws.com/dev/get-stripe-user";

export default async function getStripeUser() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (!cognitoUser) return false;
    const email = cognitoUser.attributes.email;
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    const data = await response.json();
    let customer;
    if (data.customer.data) {
      customer = data.customer.data[0];
    } else {
      customer = data.customer;
    }

    return customer;
  } catch (error) {
    console.log(error);
    return false;
  }
}
