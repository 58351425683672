import { Helmet } from "react-helmet";

import VideoPage from "../videoPage.component";

function Genny301() {
  return (
    <div>
      <Helmet>
        <title>genny | genny 301</title>
      </Helmet>
      <VideoPage
        title="Genny 301"
        description={
          <div>
            In this video, you'll learn how{" "}
            <span className="h3-genny">genny</span> returns a different result
            each time you submit a prompt, giving you the ability to quickly
            iterate on your ideas.
          </div>
        }
        backTitle="Genny 201: Write Better Prompts"
        backHref="/howTo/genny201"
        nextTitle="Genny 401: Prompt Chaining"
        nextHref="/howTo/genny401"
        embedId={null}
      />
    </div>
  );
}

export default Genny301;
