import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { Offcanvas } from "bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import createStripePortal from "../../../utilties/stripe/createStripePortal";
import { months } from "./months";
import { store } from "../../../store";
import { logout } from "../../../userSlice";

function AccountOffcanvas() {
  const email = useSelector((state) => state.user.userEmail);
  const subscription = useSelector((state) => state.user.subscription);
  const navigate = useNavigate();

  async function signOut() {
    console.log("signOut");
    try {
      await Auth.signOut();
      store.dispatch(logout());
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async function manageClickHandler(e) {
    e.target.innerHTML = `<div class="spinner-border spinner-border-sm text-info" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>`;
    if (subscription.plan === "free") {
      navigate("/upgrade");
      const accountOffcanvas = document.getElementById("accountOffcanvas");
      const openedOffcanvas = Offcanvas.getInstance(accountOffcanvas);
      openedOffcanvas.hide();
      return;
    }
    const portalUrl = await createStripePortal();
    window.location.href = portalUrl;
  }

  return (
    <div
      className="offcanvas offcanvas-start text-bg-dark"
      tabIndex="-1"
      id="accountOffcanvas"
      aria-labelledby="offcanvasLabel"
    >
      <div className="offcanvas-header">
        {email && (
          <h5 className="offcanvas-title" id="offcanvasLabel">
            {email.length < 30 ? email : email.slice(0, 28) + "..."}
          </h5>
        )}
        <button
          type="button"
          className="btn-close"
          id="account-close-btn"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <Button
        variant="outline-light"
        onClick={signOut}
        className="ms-3 mb-1"
        id="logout-btn"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        Logout
      </Button>
      <hr />
      <div className="offcanvas-body">
        {subscription && subscription.plan && (
          <p>
            {subscription.plan[0].toUpperCase() + subscription.plan.slice(1)}{" "}
            Plan
          </p>
        )}
        {subscription && subscription.plan && (
          <button
            className="btn btn-outline-info mb-3"
            id="upgrade-btn"
            onClick={manageClickHandler}
          >
            {subscription.plan === "free" ? "Upgrade" : "Manage"}
          </button>
        )}

        {subscription && subscription.monthlyPromptsRemaining && (
          <p>
            Monthly Prompts Left:{" "}
            <span className="brand-color">
              {subscription.monthlyPromptsRemaining}
            </span>
          </p>
        )}
        {subscription && subscription.nextRenewal && (
          <p>
            New Month Starts:{" "}
            {months[new Date(subscription.nextRenewal * 1000).getMonth()]}{" "}
            {new Date(subscription.nextRenewal * 1000).getDate()}
          </p>
        )}
      </div>
    </div>
  );
}

export default AccountOffcanvas;
