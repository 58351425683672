import createAd from "./createAd";
import runVIN from "./runVIN";

async function generate(
  event,
  VIN,
  modelYear,
  mileage,
  price,
  additionalDetails,
  setIsGenerating,
  output,
  setOutput
) {
  try {
    event.preventDefault();
    console.log("generating...");
    setIsGenerating(true);
    const data = await runVIN(VIN, modelYear);
    const carData = data.Results[0];
    console.log("carData:", carData);
    const adText = await createAd(
      carData.Make,
      carData.Model,
      carData.ModelYear,
      carData.TransmissionSpeeds,
      carData.TransmissionStyle,
      carData.Trim,
      carData.DisplacementL,
      carData.EngineCylinders,
      carData.ValveTrainDesign,
      carData.EngineConfiguration,
      mileage,
      price,
      additionalDetails
    );
    setOutput(adText.trimStart());
    setIsGenerating(false);
  } catch (error) {
    console.log(error);
    setIsGenerating(false);
  }
}

export default generate;
