import React from "react";

import "./footer.styles.scss";

function Footer() {
  return (
    <footer className="text-center text-lg-start bg-secondary text-light">
      {/* <!-- Section: Social media --> */}
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        {/* <!-- Left --> */}
        <div className="col-md-3 col-lg-4 col-xl-3 ms-5 ps-5 mb-4">
          <h2 className="footer-genny">genny</h2>
          {/* <p>
            Amplifying your creativity and productivity by bringing generative
            AI technology to your favorite tools.
          </p> */}
        </div>
        <div className="me-5 d-none d-lg-block">
          {/* <span>Get connected with us on social networks:</span> */}
        </div>
        {/* <!-- Left --> */}
        {/* <!-- Right --> */}
        <div>
          <a href="https://twitter.com/gennyapp" className="text-reset">
            <i className="bi bi-twitter me-4"></i>
          </a>
          <a href="https://www.tiktok.com/@gennyapp1" className="text-reset">
            <i className="bi bi-tiktok me-4"></i>
          </a>
        </div>
        {/* <!-- Right --> */}
      </section>
      {/* <!-- Section: Social media --> */}
      {/* <!-- Section: Links  --> */}
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            {/* <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h2 className="footer-genny">genny</h2>
              <p>
                Amplifying your creativity and productivity by bringing
                generative AI technology to your favorite tools.
              </p>
            </div> */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Products</h6>
              <p>
                <a href="/download#chrome" className="text-reset">
                  Chrome Extension
                </a>
              </p>
              <p>
                <a href="/sandbox" className="text-reset">
                  Sandbox
                </a>
              </p>
              <p>
                <a href="/recipes" className="text-reset">
                  Genny Meal Planning
                </a>
              </p>
              <p>
                <a href="/cars" className="text-reset">
                  Genny Cars
                </a>
              </p>
              <p>
                <a href="https://robotsanta.app" className="text-reset">
                  Robot Santa
                </a>
              </p>
              <p>
                <a href="https://twitter.com/shakeybard" className="text-reset">
                  The Shakey Bard
                </a>
              </p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Info</h6>
              <p>
                <a href="/howTo" className="text-reset">
                  How To
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Use Cases
                </a>
              </p>
              <p>
                <a href="/pricing" className="text-reset">
                  Pricing
                </a>
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <div>
                <i className="bi bi-envelope me-2 align-middle"></i>
                <a href="mailto:support@gennyapp.com" className="text-reset">
                  suport@gennyapp.com
                </a>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Legal</h6>
              <p>
                <a href="/legal/termsOfUse" className="text-reset">
                  Terms of Use
                </a>
              </p>
              <p>
                <a href="/legal/cloudServicesAgreement" className="text-reset">
                  Cloud Services Agreement
                </a>
              </p>
              <p>
                <a href="/legal/privacyPolicy" className="text-reset">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section: Links  --> */}

      {/* <!-- Copyright --> */}
      <div className="text-center p-4">© Copyright 2023: Genny, Inc.</div>
      {/* <!-- Copyright --> */}
    </footer>
  );
}

export default Footer;
