import * as bootstrap from "bootstrap";
import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { NavDropdown } from "react-bootstrap";
import { Image } from "react-bootstrap";

import "./gennyNavbar.styles.scss";
import AccountOffcanvas from "./subcomponents/accountOffcanvas.component";
import genny from "../../images/android-chrome-512x512.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavbarOffcanvas from "react-bootstrap/esm/NavbarOffcanvas";

function GennyNavbar() {
  const location = useLocation();
  const loggedInUser = useSelector((state) => state.user.loggedInUser);

  return (
    <>
      <div id="account-offcanvas-container">
        <AccountOffcanvas />
      </div>
      {/* change array member to change breakpoint */}
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          bg="dark"
          variant="light"
          expand={expand}
          className="mb-3"
        >
          <div className="row nav-container mx-auto container-fluid">
            <div className="col">
              <Navbar.Brand href="/" className="col">
                <span className="brand-color brand-font ps-3">genny</span>
              </Navbar.Brand>
            </div>
            <div className="col text-center">
              <Image src={genny} className="nav-img ms-3" />
            </div>
            <div className="col text-end p-0 align-middle toggle-div mt-4">
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                id="toggle-icon"
                className="m-0 p-0"
              >
                <i className="bi bi-list nav-toggle me-2"></i>
              </Navbar.Toggle>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                variant="dark"
                tabIndex="-2"
              >
                <Offcanvas.Header closeButton className="bg-secondary">
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <span className="brand-font brand-color fs-1">genny</span>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="bg-dark">
                  <Nav
                    className="justify-content-end flex-grow-1 pe-2"
                    variant="pills"
                  >
                    {/* <NavDropdown title="Demos" id="demo-nav-dropdown">
                      <NavDropdown.Item href="/cars" className="dropdown-item">
                        Cars
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="/recipes"
                        className="dropdown-item"
                      >
                        Recipes
                      </NavDropdown.Item>
                    </NavDropdown> */}

                    <Button
                      variant={`${
                        location.pathname === "/sandbox"
                          ? "outline-info"
                          : "outline-light"
                      }`}
                      href="/sandbox"
                      className={`ms-1 mb-1 pb-2`}
                    >
                      Sandbox
                    </Button>
                    <Button
                      variant={`${
                        location.pathname === "/download"
                          ? "outline-info"
                          : "outline-light"
                      }`}
                      href="/download"
                      className={`ms-1 mb-1 pb-2`}
                    >
                      Download
                    </Button>
                    {!loggedInUser && (
                      <Button
                        variant="outline-light"
                        href="/login"
                        className={`ms-1 mb-1 pb-2`}
                      >
                        Login/Register
                      </Button>
                    )}
                    {/* {loggedInUser && (
                      <Button
                        variant="outline-light"
                        onClick={signOut}
                        className="ms-1 mb-1"
                      >
                        Logout
                      </Button>
                    )} */}
                    {loggedInUser && (
                      <Button
                        variant="outline-light"
                        className="ms-1 mb-1 py-0 px-2"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target={`#accountOffcanvas`}
                        // aria-controls="accountOffcanvas"
                        onClick={() => {
                          const navOffcanvasEl = document.getElementById(
                            `offcanvasNavbar-expand-${expand}`
                          );
                          // const navOffcanvas =
                          //   bootstrap.Offcanvas.getOrCreateInstance(
                          //     navOffcanvasEl
                          //   );
                          // navOffcanvas.hide();
                          navOffcanvasEl.classList.remove("show");
                          const accountOffcanvasEl =
                            document.getElementById("accountOffcanvas");
                          // accountOffcanvasEl.classList.add("show");
                          const accountOffcanvas =
                            bootstrap.Offcanvas.getOrCreateInstance(
                              accountOffcanvasEl
                            );
                          accountOffcanvas.show();
                        }}
                      >
                        <i
                          className="bi bi-person-circle"
                          id="nav-account-icon"
                        ></i>
                      </Button>
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          </div>
        </Navbar>
      ))}
      {/* <div id="maintenance">
        <h1>
          All genny apps are currently disabled for maintenance. 😢 We hope to
          be back online soon!
        </h1>
        <a
          href="https://form.waitlistpanda.com/go/OaRwlflHYV9S9AkakWu8"
          data-waitlist-id="OaRwlflHYV9S9AkakWu8"
          id="waitlist-panda"
        >
          Receive updates
        </a>
      </div> */}
    </>
  );
}

export default GennyNavbar;
