import updateUser from "./updateUser";
import { store } from "../../store";

const planDays = {
  free: 20,
  creator: 200,
  pro: 500,
};

function addMonths(date, months) {
  date.setMonth(date.getMonth() + months);
  return date;
}

export default async function checkMonth() {
  let updatedUserData;
  const state = store.getState();
  if (!state.user.subscription) return;
  const updatedSubscription = structuredClone(state.user.subscription);
  const { plan, renews, nextRenewal, currentPeriodStart, currentPeriodEnd } =
    state.user.subscription;
  if (!nextRenewal) {
    updatedSubscription.nextRenewal =
      renews === "monthly"
        ? updatedSubscription.currentPeriodEnd
        : addMonths(currentPeriodStart, 1);
    //console.log("updatedSubscription:", updatedSubscription);
    updatedUserData = await updateUser({
      subscription: updatedSubscription,
    });
    //console.log(updatedUserData);
    // returning true idicates that there was an update made
    return true;
  } else if (Date.now() > nextRenewal && nextRenewal !== currentPeriodEnd) {
    updatedSubscription.nextRenewal = addMonths(nextRenewal, 1);
    updatedSubscription.monthlyPromptsRemaining = planDays[plan];
    updatedUserData = await updateUser({
      subscription: updatedSubscription,
    });
    //console.log(updatedUserData);
    // returning true idicates that there was an update made
    return true;
  } else return false;
}
