import SubSelectionTable from "../../subSelectionTable/subSelectionTable.component";

export default function OutOfPrompts() {
  return (
    <div id="upgrade-container" className="text-center">
      <h1 className="text-light mx-3 mb-3">
        Oh no! You've used up your monthly allotment of prompts.
      </h1>
      <h3 className="text-light mx-3 mb-4">
        It seems like you're using <span className="h3-genny">genny</span>{" "}
        enough to make an upgrade worthwhile...
      </h3>
      <SubSelectionTable showFree={false} />
    </div>
  );
}
