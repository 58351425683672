import { Helmet } from "react-helmet";

export default function ThankYou() {
  //gives time for subscription updates to be reflected in database
  async function waitAndReload() {
    await new Promise((r) => setTimeout(r, 2000));
    window.location.href = "http://gennyapp.com";
  }

  waitAndReload();

  return (
    <div>
      <Helmet>
        <title>genny | thank you</title>
      </Helmet>
      <div id="thank-you-page-container" className="text-center">
        <h1 className="gradient-text headline">Thank you for using</h1>
        <h1>
          <span className="h1-genny">genny!</span>
        </h1>
        <h4 className="text-light my-4">
          You will be redirected very shortly.
        </h4>
      </div>
    </div>
  );
}
