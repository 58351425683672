import "./videoNav.styles.scss";

function VideoNav(
  { backTitle = null, backHref = null, nextTitle = null, nextHref } = null
) {
  return (
    <div>
      <div className="row nav-container mx-auto container-fluid align-items-center">
        <div className="col video-nav-col" id="video-nav-back">
          {backTitle && backHref && (
            <div className="d-flex flex-row">
              <a href={backHref} className="text-decoration-none text-info">
                <div className="">{backTitle}</div>
                <div>
                  <i className="bi bi-arrow-left"></i>
                </div>
              </a>
            </div>
          )}
        </div>
        <div className="col video-nav-col" id="video-nav-home">
          <a href="/howTo" className="text-decoration-none text-info">
            <i className="bi bi-house"></i> Contents
          </a>
        </div>
        <div className="col video-nav-col" id="video-nav-next">
          {nextTitle && nextHref && (
            <div className="d-flex flex-row-reverse">
              <a href={nextHref} className="text-decoration-none text-info">
                <div>{nextTitle}</div>
                <div>
                  <i className="bi bi-arrow-right"></i>
                </div>
              </a>
            </div>
          )}{" "}
        </div>
      </div>
    </div>
  );
}

export default VideoNav;
