import { Helmet } from "react-helmet";

export default function TermsOfUse() {
  return (
    <div>
      <Helmet>
        <title>genny | terms of use</title>
      </Helmet>
      <div id="terms-of-use-container">
        <iframe
          className="legal-doc-iframe"
          title="Cloud Services Agreement"
          src="https://docs.google.com/gview?url=https://gennyapp.com/legalDocs/tou.pdf&embedded=true"
        >
          <p>
            Your browser does not support iframes. The document can be
            downloaded at{" "}
            <a href="https://gennyapp.com/legalDocs/tou.pdf">
              https://gennyapp.com/legalDocs/tou.pdf
            </a>
            .
          </p>
        </iframe>
        {/* <h1>cloudServicesAgreement</h1>
        <Document file="cas.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p> */}
      </div>
    </div>
  );
}
