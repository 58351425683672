import { store } from "../../../../../store/index";

import buildPrompt from "./buildPrompt";
import checkAccount from "../../../../../utilties/users/checkAccount";
import screenPrompt from "../../../../../utilties/prompts/screenPrompt";
import runPrompt from "../../../../../utilties/prompts/runPrompt";
import { addMeal, generatingToggle } from "../../recipesSlice";

async function generateMeals() {
  const { recipes } = store.getState();
  let { meals, numberOfMeals } = recipes;
  const accountStatus = checkAccount("recipes");
  if (!accountStatus) return;
  store.dispatch(generatingToggle());
  let loops = 0;
  while (meals.length < numberOfMeals && loops < 15) {
    try {
      console.log("meals:", meals);
      const prompt = await buildPrompt();
      const passedScreening = await screenPrompt(prompt, "recipes");
      if (!passedScreening) {
        console.log("failed screening");
        return new Error("failed prompt screening");
      }
      const data = await runPrompt(prompt, "recipes", 0.9);
      const recipeObject = JSON.parse(
        data.message.choices[0].text.replace(/"(?:""|[^"])+"/g, function (x) {
          return x.replace(/[^\S\r\n]*[\n\r]\s*/g, "[RETURN]");
        })
      );
      console.log(recipeObject);
      store.dispatch(addMeal(recipeObject));
      meals = store.getState().recipes.meals;
    } catch (error) {
      console.log(error);
    }
    loops++;
  }
  store.dispatch(generatingToggle());
  return true;
}

export default generateMeals;
