function VideoHeader({ title, description }) {
  return (
    <div>
      <h1 className="headline gradient-text">{title}</h1>
      <h3 className="m-3 p-4 border border-secondary rounded">{description}</h3>
    </div>
  );
}

export default VideoHeader;
