import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBTypography,
  MDBIcon,
  MDBCardTitle,
} from "mdb-react-ui-kit";

import goToStripeCheckout from "../../utilties/stripe/goToStripeCheckout";
import { setRedirectAfterLogin } from "../../userSlice";

export default function SubSelectionTable({ showFree = true }) {
  const [billing, setBilling] = useState("monthly");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedInUser } = useSelector((state) => state.user);

  async function onClickHandler(e) {
    e.target.innerHTML = `<div class="spinner-border spinner-border-sm text-info" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>`;
    if (!loggedInUser) {
      dispatch(setRedirectAfterLogin("/selectAccountType"));
      navigate("/login");
    }
    const plan = e.target.value;
    const checkoutUrl = await goToStripeCheckout(billing, plan);
    window.location.href = checkoutUrl;
  }

  return (
    <div>
      <MDBContainer className="mt-3 mb-5">
        <div className="text-center">
          <div
            className="btn-group mb-3"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio1"
              autoComplete="off"
              defaultChecked
              onClick={() => setBilling("monthly")}
            />
            <label className="btn btn-outline-light" htmlFor="btnradio1">
              Monthly billing
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio2"
              autoComplete="off"
              onClick={() => setBilling("annual")}
            />
            <label className="btn btn-outline-light" htmlFor="btnradio2">
              Annual billing (3 months FREE)
            </label>
          </div>
        </div>

        <MDBRow className="d-flex flex-row justify-content-center my-3">
          {showFree && (
            <MDBCol md="4" className="p-3">
              <MDBCard>
                <MDBCardBody className="mx-2">
                  <MDBCardTitle className="my-2 text-dark">Free</MDBCardTitle>
                  <p className="text-muted">
                    Great for learning what genny can do.
                  </p>
                  {billing === "monthly" && (
                    <p className="h2 fw-bold text-dark">
                      $0
                      <small
                        className="text-muted"
                        style={{ fontSize: "18px" }}
                      >
                        /mo
                      </small>
                    </p>
                  )}
                  {billing === "annual" && (
                    <p className="h2 fw-bold text-dark">
                      $0
                      <small
                        className="text-muted"
                        style={{ fontSize: "18px" }}
                      >
                        /yr
                      </small>
                    </p>
                  )}
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn btn-dark mb-2 mt-3"
                      value="free"
                      onClick={onClickHandler}
                    >
                      Choose Free
                    </button>
                  </div>
                </MDBCardBody>

                <MDBCardFooter>
                  <p
                    className="text-uppercase fw-bold text-dark text-start"
                    style={{ fontSize: "12px" }}
                  >
                    What's included:
                  </p>

                  <MDBTypography listUnStyled className="mb-3 px-2 text-start">
                    <li className="mb-3">
                      <div className="d-flex flex-row">
                        <MDBIcon
                          fas
                          icon="check"
                          className="text-success me-3"
                        />
                        <small className="text-muted">
                          20 prompts per month<sup>*</sup>
                        </small>
                      </div>
                    </li>
                    <li className="mb-3">
                      <div className="d-flex flex-row">
                        <MDBIcon
                          fas
                          icon="check"
                          className="text-success me-3"
                        />
                        <small className="text-muted">
                          Access to all genny apps
                        </small>
                      </div>
                    </li>
                    <li className="mb-3">
                      <div className="d-flex flex-row">
                        <MDBIcon
                          fas
                          icon="check"
                          className="text-success me-3"
                        />
                        <small className="text-muted">
                          No credit card required
                        </small>
                      </div>
                    </li>
                  </MDBTypography>
                </MDBCardFooter>
              </MDBCard>
            </MDBCol>
          )}

          <MDBCol md="4" className="border border-info rounded p-3">
            <MDBCard>
              <MDBCardBody className="mx-2">
                <MDBCardTitle className="my-2 text-dark">Creator</MDBCardTitle>
                <p className="text-muted">The perfect plan for most users.</p>
                {billing === "monthly" && (
                  <p className="h2 fw-bold text-dark">
                    $5
                    <small className="text-muted" style={{ fontSize: "18px" }}>
                      /mo
                    </small>
                  </p>
                )}
                {billing === "annual" && (
                  <p className="h2 fw-bold text-dark">
                    $45
                    <small className="text-muted" style={{ fontSize: "18px" }}>
                      /yr
                    </small>
                  </p>
                )}
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-dark mb-2 mt-3"
                    value="creator"
                    onClick={onClickHandler}
                  >
                    Choose Creator
                  </button>
                </div>
              </MDBCardBody>

              <MDBCardFooter>
                <p
                  className="text-uppercase fw-bold text-dark text-start"
                  style={{ fontSize: "12px" }}
                >
                  What's included:
                </p>

                <MDBTypography listUnStyled className="mb-0 px-2 text-start">
                  <li className="mb-3">
                    <div className="d-flex flex-row">
                      <MDBIcon fas icon="check" className="text-success me-3" />
                      <small className="text-muted">
                        200 prompts per month<sup>*</sup>
                      </small>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex flex-row">
                      <MDBIcon fas icon="check" className="text-success me-3" />
                      <small className="text-muted">
                        Access to all genny apps
                      </small>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex flex-row">
                      <MDBIcon fas icon="check" className="text-success me-3" />
                      <small className="text-muted">Email support</small>
                    </div>
                  </li>
                </MDBTypography>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>

          <MDBCol md="4" className="p-3">
            <MDBCard>
              <MDBCardBody className="mx-2">
                <MDBCardTitle className="my-2 text-dark">Pro</MDBCardTitle>
                <p className="text-muted">For the uber-productive creative.</p>
                {billing === "monthly" && (
                  <p className="h2 fw-bold text-dark">
                    $10
                    <small className="text-muted" style={{ fontSize: "18px" }}>
                      /mo
                    </small>
                  </p>
                )}
                {billing === "annual" && (
                  <p className="h2 fw-bold text-dark">
                    $90
                    <small className="text-muted" style={{ fontSize: "18px" }}>
                      /yr
                    </small>
                  </p>
                )}
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-dark mb-2 mt-3"
                    value="pro"
                    onClick={onClickHandler}
                  >
                    Choose Pro
                  </button>
                </div>
              </MDBCardBody>

              <MDBCardFooter>
                <p
                  className="text-uppercase fw-bold text-dark text-start"
                  style={{ fontSize: "12px" }}
                >
                  What's included:
                </p>

                <MDBTypography listUnStyled className="mb-0 px-2 text-start">
                  <li className="mb-3">
                    <div className="d-flex flex-row">
                      <MDBIcon fas icon="check" className="text-success me-3" />
                      <small className="text-muted">
                        500 prompts per month<sup>*</sup>
                      </small>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex flex-row">
                      <MDBIcon fas icon="check" className="text-success me-3" />
                      <small className="text-muted">
                        Access to all genny apps
                      </small>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex flex-row">
                      <MDBIcon fas icon="check" className="text-success me-3" />
                      <small className="text-muted">
                        Email and phone support
                      </small>
                    </div>
                  </li>
                </MDBTypography>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div>
        <hr className="text-light mx-5" />
        <p className="text-start text-muted mx-5 px-5">
          <small>
            <sup>*</sup> A "prompt" is a command sent to genny, e.g., "Write a
            haiku about guinea pigs." The output from a single prompt on genny
            is limited to approximately 1000 words. See our{" "}
            <a href="/legal/cloudServicesAgreement" className="link-info">
              cloud services agreement
            </a>{" "}
            for more information.
          </small>
        </p>
      </div>
    </div>
  );
}
