import emailAlert from "../emailAlert";
import setUserState from "./setUserState";
import { store } from "../../store";
import updateUser from "./updateUser";

export default async function handleFlaggedScreening(prompt, data) {
  console.log("handle flagged screening");
  const state = store.getState();
  const { id, cognitoUsername, rejectedScreens } = state.user;
  const updatedUser = await updateUser({
    rejectedScreens: rejectedScreens + 1,
  });
  let emailBody = `prompt: ${prompt}\n\n`;
  emailBody += `user: ${id}\ncognitoUser:${cognitoUsername}\napp: Chrome Extension v.0.2.0\n\n`;
  emailBody += JSON.stringify(data).replaceAll(",", ",\n");

  emailAlert({
    subject: "Failed Prompt Screening",
    emailBody,
  });
  setUserState();
}
