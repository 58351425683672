const baseUrl = "https://twitter.com/intent/tweet";

function clickToTweet(baseText) {
  const tweetText =
    `🤯 Just made this with @gennyapp:%0A%0A${baseText}`.replaceAll("#", "%23");
  // TODO add "via" param: https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent
  //const encodedTweetText = encodeURI(tweetText);
  const url = `${baseUrl}?text=${tweetText}`;
  window.open(url);
}

export default clickToTweet;
