import setUserState from "./setUserState";
import { store } from "../../store";
import updateUser from "./updateUser";

export default async function deductPrompt() {
  const state = store.getState();
  const { subscription } = state.user;
  const updatedSubscription = structuredClone(subscription);
  updatedSubscription.monthlyPromptsRemaining--;
  const newUserData = await updateUser({ subscription: updatedSubscription });
  setUserState();
}
