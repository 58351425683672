import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="text-center text-light my-5 py-5">
      <h1>Oops! You seem to be lost.</h1>
      <p>Here are some helpful links:</p>
      <p>
        <Link to="/">Home</Link>
      </p>
      <p>
        <Link to="/login">Login</Link>
      </p>
      <p>
        <Link to="/download">Download</Link>
      </p>
    </div>
  );
}
