import { Helmet } from "react-helmet";

import VideoPage from "../videoPage.component";

function Genny201() {
  return (
    <div>
      <Helmet>
        <title>genny | genny 201</title>
      </Helmet>
      <VideoPage
        title="Genny 201"
        description={
          <div>
            In this video, you'll learn how to write better prompts, leading you
            to better results and getting you there faster.
          </div>
        }
        backTitle="Genny 101: What Is a Prompt?"
        backHref="/howTo/genny101"
        nextTitle="Genny 301: Varrying Results, One of Genny's Many Superpowers"
        nextHref="/howTo/genny301"
        embedId={null}
      />
    </div>
  );
}

export default Genny201;
