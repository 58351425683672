// eslint-disable-next-line
import { View, Image, Text } from "@aws-amplify/ui-react";

//import genny from "../../../images/android-chrome-512x512.png";

const components = {
  Header() {
    return (
      <div>
        {/* <View textAlign="center">
          <Image alt="genny logo" src={genny} width="20%" />
        </View> */}
        <Text color="#fff" marginBottom="5px">
          Sign in or create your free account.
        </Text>
      </div>
    );
  },
};

export default components;
