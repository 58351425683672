import { createSlice } from "@reduxjs/toolkit";

const defaultsData = window.localStorage.getItem("recipeDefaults");
let defaults = {};
if (defaultsData) {
  defaults = JSON.parse(defaultsData);
}

const initialState = {
  meals: [],
  isGenerating: false,
  numberOfDiners:
    defaults && defaults.numberOfDiners ? defaults.numberOfDiners : 2,
  numberOfMeals:
    defaults && defaults.numberOfMeals ? defaults.numberOfMeals : 1,
  allergies: {
    dairy: defaults && defaults.dairyAllergy === "true" ? true : false,
    eggs: defaults && defaults.eggsAllergy === "true" ? true : false,
    fish: defaults && defaults.fishAllergy === "true" ? true : false,
    shellfish: defaults && defaults.shellfishAllergy === "true" ? true : false,
    treenut: defaults && defaults.treenutAllergy === "true" ? true : false,
    peanut: defaults && defaults.peanutAllergy === "true" ? true : false,
    gluten: defaults && defaults.glutenAllergy === "true" ? true : false,
    soybean: defaults && defaults.soybeanAllergy === "true" ? true : false,
  },
  special: {
    vegetarian:
      defaults && defaults.vegetarianSpecial === "true" ? true : false,
    vegan: defaults && defaults.veganSpecial === "true" ? true : false,
    pescatarian:
      defaults && defaults.pescatarianSpecial === "true" ? true : false,
    "pork-free":
      defaults && defaults["pork-freeSpecial"] === "true" ? true : false,
    kosher: defaults && defaults.kosherSpecial === "true" ? true : false,
  },
};

export const recipesSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    updateNumber: (state, action) => {
      state[action.payload.target] = action.payload.value;
    },
    checkAllergy: (state, action) => {
      state.allergies[action.payload] = true;
    },
    uncheckAllergy: (state, action) => {
      state.allergies[action.payload] = false;
    },
    checkSpecial: (state, action) => {
      state.special[action.payload] = true;
    },
    uncheckSpecial: (state, action) => {
      state.special[action.payload] = false;
    },
    generatingToggle: (state) => {
      state.isGenerating = !state.isGenerating;
    },
    addMeal: (state, action) => {
      state.meals.push(action.payload);
    },
    replaceMeal: (state, action) => {
      state.meals[action.payload.target] = action.payload.meal;
    },
    resetMeals: (state) => {
      state.meals = [];
    },
  },
});

export const {
  updateNumber,
  checkAllergy,
  uncheckAllergy,
  checkSpecial,
  uncheckSpecial,
  generatingToggle,
  addMeal,
  replaceMeal,
  resetMeals,
} = recipesSlice.actions;

export default recipesSlice.reducer;
