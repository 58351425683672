import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import "./recipes.styles.scss";
import InitialInput from "./subcomponents/intialInput.component";
import Loading from "./subcomponents/loading.component";
import Output from "./subcomponents/output.component";

function Recipes() {
  const meals = useSelector((state) => state.recipes.meals);
  const isGenerating = useSelector((state) => state.recipes.isGenerating);
  return (
    <div id="recipes-container" className="pb-3">
      <Helmet>
        <title>genny | recipes</title>
      </Helmet>
      <div className="header">
        <h1 className="recipes-h1 p-2">Fast and Easy Meal Planning</h1>
      </div>
      <InitialInput />
      <br />
      {meals && meals.length > 0 && <Output />}
      {isGenerating && <Loading />}
    </div>
  );
}

export default Recipes;
