import checkMonth from "./checkMonth";
import createNewFreeSubscription from "../stripe/createNewFreeSubscription";
import { store } from "../../store";
import getStripeUser from "../stripe/getStripeUser";
import getUser from "./getUser.js";
import {
  setCognitoUsername,
  setDisabled,
  setIsLoading,
  setMode,
  setRejectedScreens,
  setStripeId,
  setSubscription,
  setUserEmail,
} from "../../userSlice";

async function setUserState(email) {
  const state = store.getState();
  const { isLoading } = state.user;
  if (isLoading) {
    console.log("already loading");
    return;
  }
  //console.log("running setUserState...");
  store.dispatch(setIsLoading(true));
  const userData = await getUser();
  //console.log("userData:", userData);
  if (userData.mode === "god") store.dispatch(setMode(userData.mode));
  store.dispatch(setCognitoUsername(userData.cognitoUsername));
  store.dispatch(setDisabled(userData.disabled));
  store.dispatch(setRejectedScreens(userData.rejectedScreens));
  store.dispatch(setStripeId(userData.stripeId));
  if (!userData.subscription) {
    //console.log("no subscription...");
    let stripeId = userData.stripeId;
    if (!stripeId) {
      //console.log("no stripeId...");
      const stripeIdData = await getStripeUser();
      //console.log("stripeIdData:", stripeIdData);
      if (stripeIdData && stripeIdData.id) stripeId = stripeIdData.id;
      //console.log("stripeId:", stripeId);
    }
    const subscriptionData = await createNewFreeSubscription(stripeId);
    //console.log("subscriptionData:", subscriptionData);
    userData.subscription = subscriptionData;
  }
  store.dispatch(setSubscription(userData.subscription));
  store.dispatch(setUserEmail(email));
  const updated = await checkMonth();
  store.dispatch(setIsLoading(false));
  return userData;
}

export default setUserState;
