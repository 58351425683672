import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";

import "./settingsBar.styles.scss";
import { setPitch, setRate } from "../pages/sandbox/sandboxSlice";
import voiceSelectOnChangeHandler from "./handlers/voiceSelectOnChangeHandler";

function CustomToggle({ children, eventKey }) {
  const onClickHandler = useAccordionButton(eventKey, () => {});

  return (
    <button
      type="button"
      className="toggle-btn bg-dark border-light rounded"
      onClick={onClickHandler}
    >
      {children}
    </button>
  );
}

export default function SettingsBar() {
  const dispatch = useDispatch();
  const { activeVoice, pitch, rate } = useSelector((state) => state.sandbox);

  return (
    <div className="settings-bar-container text-end px-1">
      <Accordion>
        <Card className="bg-dark border-0">
          <Card.Header className="border-0 bg-dark">
            <CustomToggle eventKey="0">
              <i className="toggle-img bi bi-gear text-light fs-4"></i>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse className="mx-3 my-2 rounded" eventKey="0">
            <Card.Body className="text-start text-light">
              {"speechSynthesis" in window &&
                window.speechSynthesis.getVoices().length > 0 && (
                  <div className="text-start text-light fs-3">
                    <i className="bi bi-headphones"></i>
                    <div className="input-group mb-3">
                      <label className="input-group-text" htmlFor="voiceSelect">
                        Voice
                      </label>
                      <select
                        className="form-select"
                        id="voiceSelect"
                        onChange={voiceSelectOnChangeHandler}
                        defaultValue={activeVoice}
                      >
                        {window.speechSynthesis.getVoices().map((voice, i) => {
                          return (
                            <option key={i} value={voice.voiceURI}>
                              {voice.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor="pitchRange"
                            className="form-label fs-6"
                          >
                            Pitch
                          </label>
                          <input
                            onChange={(e) => {
                              dispatch(setPitch(e.target.value));
                              window.localStorage.setItem(
                                "genny-settings-pitch",
                                e.target.value
                              );
                            }}
                            type="range"
                            className="form-range"
                            min="0"
                            max="2"
                            step="0.1"
                            id="pitchRange"
                            value={pitch}
                          ></input>
                        </div>
                        <div className="col">
                          <label
                            htmlFor="rateRange"
                            className="form-label fs-6"
                          >
                            Speed
                          </label>
                          <input
                            onChange={(e) => {
                              dispatch(setRate(e.target.value));
                              window.localStorage.setItem(
                                "genny-settings-rate",
                                e.target.value
                              );
                            }}
                            type="range"
                            className="form-range"
                            min="0.1"
                            max="3"
                            step="0.1"
                            id="rateRange"
                            value={rate}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
