import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import "./sandbox.styles.css";
import SandboxInput from "./sandboxInput.component";
import sandboxRunPromptStream from "./utilities/sandboxRunPromptStream";
import SettingsBar from "../../settingsBar/settingsBar.component";
import { setActiveVoice, setPitch, setRate } from "./sandboxSlice";

let utterance = null;

function Sandbox() {
  const dispatch = useDispatch();
  const { generateResult, inputsArray } = useSelector((state) => state.sandbox);

  function testClickHandler() {
    console.log("click...");
    sandboxRunPromptStream("Write a haiku about Buddy the guinea pig.");
  }

  useEffect(() => {
    // load settings from local storage
    const newActiveVoice = window.localStorage.getItem("genny-settings-voice");
    if (newActiveVoice) dispatch(setActiveVoice(newActiveVoice));
    const pitchSetting = window.localStorage.getItem("genny-settings-pitch");
    if (pitchSetting) dispatch(setPitch(pitchSetting));
    const rateSetting = window.localStorage.getItem("genny-settings-rate");
    if (rateSetting) dispatch(setRate(rateSetting));
  }, []);

  return (
    <div id="sandbox-container">
      <Helmet>
        <title>genny | sandbox</title>
      </Helmet>
      {/* <button className="btn btn-danger" onClick={testClickHandler}>
        Test
      </button>
      <h1 className="text-light">{generateResult.trim()}</h1> */}
      <SettingsBar />
      <div className="prompt-container mx-3 px-1" id="prompt-container-1">
        <label htmlFor="prompt-1" className="text-light">
          Starting prompt:
        </label>
        <br />
        {inputsArray.map((el, index) => {
          return (
            <div key={`sbi${index}`}>
              <SandboxInput number={el} utterance={utterance} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Sandbox;
