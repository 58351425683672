const API_ENDPOINT = "https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues";

async function runVIN(VIN, modelYear) {
  try {
    const queryURL = modelYear
      ? `${API_ENDPOINT}/${VIN}?format=json?modelyear=${modelYear}`
      : `${API_ENDPOINT}/${VIN}?format=json`;
    const response = await fetch(queryURL);
    console.log("Response:", response);
    if (response && response.status === 200) {
      const data = await response.json();
      return data;
    } else throw new Error("Problem with response from VIN query");
  } catch (error) {
    console.error(error);
    return false;
  }
}

export default runVIN;
