import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "./audioControls.styles.scss";
import { setActiveAudio } from "./sandboxSlice";

let textToSpeak;

function AudioControls({ number, utterance }) {
  const dispatch = useDispatch();
  const { activeAudio, activeVoice, rate } = useSelector(
    (state) => state.sandbox
  );

  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(false);

  async function createUtterance() {
    utterance = new SpeechSynthesisUtterance(textToSpeak);
    utterance.addEventListener("start", () => {
      setPlaying(true);
      setPaused(false);
      dispatch(setActiveAudio(number));
    });
    utterance.addEventListener("end", () => {
      setPlaying(false);
      setPaused(false);
      dispatch(setActiveAudio(null));
    });
    utterance.addEventListener("pause", () => {
      setPaused(true);
      setPlaying(false);
      dispatch(setActiveAudio(null));
    });
    utterance.addEventListener("resume", () => {
      setPlaying(true);
      setPaused(false);
    });
  }

  async function setUtteranceVoice() {
    console.log("setting voice");
    console.log("URI", activeVoice);
    console.log("voices list:", window.speechSynthesis.getVoices());
    if (activeVoice && window.speechSynthesis.getVoices().length > 0) {
      window.speechSynthesis.getVoices().forEach((v) => {
        console.log(v.voiceURI);
        console.log(activeVoice);
        if (v.voiceURI === activeVoice && utterance) {
          console.log("match!");
          utterance.voice = v;
        }
      });
    }
  }

  async function getTextToSpeak() {
    const text = document.getElementById(`prompt-input-${number}`).value;
    return text;
  }

  async function playClickHandler() {
    // console.log("speaking:", speechSynthesis.speaking);
    // console.log("paused:", speechSynthesis.paused);
    // console.log("utterance:", utterance);
    if (speechSynthesis.speaking && !speechSynthesis.paused) {
      speechSynthesis.pause();
      return;
    } else if (speechSynthesis.paused) {
      speechSynthesis.resume();
      return;
    } else if (!utterance || utterance.text !== (await getTextToSpeak())) {
      textToSpeak = await getTextToSpeak();
      if (textToSpeak) {
        await createUtterance();
      }
    }
    await setUtteranceVoice();
    const pitch = window.localStorage.getItem("genny-settings-pitch");
    if (pitch && utterance) utterance.pitch = pitch;
    const rate = window.localStorage.getItem("genny-settings-rate");
    if (rate && utterance) utterance.rate = rate;
    console.log(utterance);
    speechSynthesis.cancel();
    speechSynthesis.speak(utterance);
  }

  //prevents controls from rendering if sspeech synthesis is not supported
  if ("speechSynthesis" in window) {
    return (
      <div className="audio-controls-container">
        <button
          className="sandbox-btn audio-controls-btn"
          onClick={() => {
            speechSynthesis.cancel();
            utterance = null;
            console.log(utterance);
          }}
        >
          <i className="bi bi-stop-fill"></i>
        </button>

        {(!activeAudio || activeAudio === number) && (
          <button
            className="sandbox-btn audio-controls-btn "
            onClick={playClickHandler}
          >
            {playing && !paused ? (
              <i className="bi bi-pause-fill" />
            ) : (
              <i className="bi bi-play-fill" />
            )}
          </button>
        )}
      </div>
    );
  }
}

export default AudioControls;
