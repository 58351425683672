import { Auth } from "aws-amplify";

const API_ENDPOINT =
  "https://oy1hu45rzh.execute-api.us-east-1.amazonaws.com/dev/create-new-free-subscription";

export default async function createNewFreeSubscription(stripeId) {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (!cognitoUser) return false;
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        stripeId,
      }),
    });
    const data = await response.json();
    const subscriptionData = data.subscriptionData;
    if (subscriptionData) {
      return {
        plan: "free",
        renews: "monthly",
        currentPeriodStart: subscriptionData["current_period_start"],
        currentPeriodEnd: subscriptionData["current_period_end"],
        startDate: subscriptionData["start_date"],
        monthlyPromptsRemaining: 20,
      };
    } else return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}
