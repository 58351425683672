import checkAccount from "../../../../utilties/users/checkAccount";
import runPrompt from "../../../../utilties/prompts/runPrompt";
import screenPrompt from "../../../../utilties/prompts/screenPrompt";

async function createAd(
  make,
  model,
  modelYear,
  transmissionSpeeds,
  transmissionStyle,
  trim,
  displacementL,
  engineCylinders,
  valveTrainDesign,
  engineConfiguration,
  mileage,
  price,
  additionalDetails
) {
  const processedMake =
    make.charAt(0).toUpperCase() + make.substr(1).toLowerCase();
  let prompt = `Write an ad for this vehicle: ${modelYear} ${processedMake} ${model} ${trim} with ${mileage} miles, a price of $${price}`;
  // Transmission
  if (transmissionSpeeds && transmissionStyle) {
    prompt += `, a ${transmissionSpeeds} speed ${transmissionStyle} transmission`;
  } else if (transmissionStyle) {
    prompt += `, a ${transmissionStyle} transmission`;
  }
  // Engine
  let engine;
  if (engineCylinders === "6" && engineConfiguration === "V-Shaped") {
    engine = `${displacementL.slice(
      0,
      3
    )} liter ${valveTrainDesign} V-6 engine`.trimStart();
  } else if (engineCylinders === "8" && engineConfiguration === "V-Shaped") {
    engine = `${displacementL.slice(
      0,
      3
    )} liter ${valveTrainDesign} V-8 engine`.trimStart();
  } else {
    engine = `${displacementL.slice(
      0,
      3
    )} liter ${engineConfiguration} ${engineCylinders} cylinder ${valveTrainDesign} engine`.trimStart();
  }
  prompt += `, ${engine}`;
  // Additonal Details
  if (additionalDetails) {
    prompt += `, and ${additionalDetails}`;
  }
  prompt += ".";
  const accountStatus = checkAccount("cars");
  if (!accountStatus) return;
  const passedScreening = await screenPrompt(prompt, "carz");
  if (!passedScreening) {
    console.log("Prompt failed screening.");
    return false;
  }
  const data = await runPrompt(prompt, "carz");
  console.log("data:", data);
  return data.message.choices[0].text;
}

export default createAd;
