import checkAccount from "../../../../utilties/users/checkAccount";
import logResult from "../../../../utilties/prompts/logResult";
import sandboxRunPromptStream from "./sandboxRunPromptStream";
import screenPrompt from "../../../../utilties/prompts/screenPrompt";
import { setInputsArray, setNumInputs } from "../sandboxSlice";
import { setRedirectAfterLogin } from "../../../../userSlice";
import { store } from "../../../../store";

export default async function generate(event) {
  const accountStatus = checkAccount();
  if (!accountStatus) return;
  const callerNumber = parseInt(event.target.id.slice(-1));
  try {
    const prompt = document.getElementById(
      `prompt-input-${callerNumber}`
    ).value;
    // delete any elements below the caller
    store.dispatch(setNumInputs(callerNumber + 1));
    await new Promise((r) => setTimeout(r, 100));
    const newInputsArray = [];
    for (let i = 1; i <= callerNumber + 1; i++) {
      newInputsArray.push(i);
    }
    store.dispatch(setInputsArray(newInputsArray));
    //delay to give time for state to be updated and the new element to be created;
    await new Promise((r) => setTimeout(r, 100));
    document.getElementById(`prompt-input-${callerNumber}`).value = prompt;
    const targetEl = document.getElementById(
      `prompt-input-${callerNumber + 1}`
    );
    //   let data = null;
    //   let loops = 1;
    const waitingText =
      "🤖 genny is working on it. This shouldn't take long... 🤖";
    targetEl.value = waitingText;
    const screening = await screenPrompt(prompt, "sandbox");
    logResult(prompt, screening.result, "screen");
    if (!screening.passed) {
      console.log("failed screening");
      targetEl.value =
        "Your prompt has failed content screening. This is due to either prohibited content or a network error. If the latter, please try again.";
      return false;
    }
    const data = await sandboxRunPromptStream(targetEl, prompt);
    logResult(prompt, data, "generate");
  } catch (error) {
    await new Promise((r) => setTimeout(r, 1000));
    const errorMsgTarget = document.getElementById(
      `prompt-input-${callerNumber + 1}`
    );
    errorMsgTarget.value = error;
    if (error === "The user is not authenticated") {
      errorMsgTarget.value =
        "You must be logged in to play in genny's sandbox!!!";
      store.dispatch(setRedirectAfterLogin("/sandbox"));
      setTimeout(() => {
        window.location.navigate("https://gennyapp.com/login");
      }, 1 * 5000);
    }
  }
}
