import { Helmet } from "react-helmet";

import VideoPage from "../videoPage.component";

function Genny401() {
  return (
    <div>
      <Helmet>
        <title>genny | genny 401</title>
      </Helmet>
      <VideoPage
        title="Genny 401"
        description="
          In this video, you'll learn how to chain your prompts together to
          quickly progress from idea to outline to first draft."
        backTitle="Genny 301: Varrying Results, One of Genny's Many Superpowers"
        backHref="/howTo/genny301"
        embedId={null}
      />
    </div>
  );
}

export default Genny401;
